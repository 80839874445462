import { o } from './chunk-b04e620d.js';
export { o as default } from './chunk-b04e620d.js';

o.decode;
o.encode;
o.parse;
o.stringify;

var decode = o.decode;
var encode = o.encode;
var parse = o.parse;
var stringify = o.stringify;

export { decode, encode, parse, stringify };
